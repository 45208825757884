import { Injectable } from '@angular/core'
import { Subject }    from 'rxjs/Subject'

@Injectable()
export class EventsService {

    private refreshAnnounce = new Subject<Object>()
    refreshAnnounced$ = this.refreshAnnounce.asObservable();

    private logoutAnnounce = new Subject<Object>()
    logoutAnnounced$ = this.logoutAnnounce.asObservable();

    announceInfoUpdate() {
        this.refreshAnnounce.next();
    }

    announceLogout() {
        this.logoutAnnounce.next();
    }
}