import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, ResponseContentType } from '@angular/http';
import { LoginService } from './login.service';
import { StorageService } from './storage.service';
import { EventsService } from './events.service';

@Injectable()

export class AppHttpService {

    private options: RequestOptions;
    private apiUrl = "api/v1";

    constructor(
        private http: Http,
        private storageService: StorageService,
        private eventsService: EventsService) {
        this.initHeaders();
    }

    private initHeaders(): void {
        this.options = new RequestOptions({
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        });
    }

    getAuthOptions(): RequestOptions {
        return new RequestOptions({
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.storageService.get('access_token')
            })
        });
    }
    getOptionsFile(): RequestOptions {
        return new RequestOptions({
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
            responseType: ResponseContentType.Blob
        });
    }

    public checkResponseStatus(response) {
        if (response.status < 200 || response.status >= 300) {
            throw new Error('Bad response status: ' + response.status);
        }
    }
    private extractData(response) {
        if (response.status < 200 || response.status >= 300) {
            throw new Error('Bad response status: ' + response.status);
        }

        if (response["_body"] != "") {
            let body = response.json();
            return body || {};
        }
    }

    private buildJSON(message) {
        let obj = {};
        if (message.controls) {
            for (var property in message.controls) {
                if (message.controls.hasOwnProperty(property)) {
                    obj[property] = message.controls[property]._value;
                }
            }
            return JSON.stringify(obj);
        }
        else return JSON.stringify(message);
    }

    refreshAccessToken(): Promise<void> {
        var encodedData = encodeURI("grant_type=refresh_token&refresh_token=" + this.storageService.get('refresh_token'));
        return this.http.post('/connect/token', encodedData,
            new RequestOptions({
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            }))
            .toPromise()
            .then((response) => {
                var newKeys = this.extractData(response);
                this.updateTokens(newKeys['access_token'], this.storageService.get('refresh_token'), this.storageService.isPersistent());
                this.initHeaders();
                return Promise.resolve();
            }).catch((err) => {
                this.eventsService.announceLogout();
                return Promise.reject(err);
            });
    }

    postEncoded(route, message, fromApi) {
        let options = new RequestOptions({
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        });
        let data = encodeURI(message);
        let url = fromApi ? this.apiUrl + route : route;

        return this.http.post(url, data, options)
            .toPromise()
            .then(this.extractData)
            .catch((err) => { return Promise.reject(err.message || err); });
    }

    delete(route, authentication, fromApi) {
        let url = fromApi ? this.apiUrl + route : route;
        let options = authentication ? this.getAuthOptions() : this.options;
        return this.http.delete(url, options)
            .toPromise()
            .catch((err) => {
                if (err.status === 401 &&  this.storageService.get('refresh_token') != null) {
                    return this.refreshAccessToken().then(() => {
                        return this.delete(route, authentication, fromApi);
                    }).catch((err) => { return Promise.reject(err.message || err); });
                }
                else if (err.status === 401) {
                    this.eventsService.announceLogout();
                    return Promise.reject(err.message || err);
                }
                else {
                    return Promise.reject(err.message || err);
                }
            });
    }

    get(route, authentication, fromApi) {
        let url = fromApi ? this.apiUrl + route : route;
        let options = authentication ? this.getAuthOptions() : this.options;
        return this.http.get(url, options)
            .toPromise()
            .then(this.extractData)
            .catch((err) => {
                if (err.status === 401 && this.storageService.get('refresh_token') != null) {
                    return this.refreshAccessToken().then(() => {
                        return this.get(route, authentication, fromApi);
                    }).catch((err) => { return Promise.reject(err.message || err); });
                }
                else if (err.status === 401) {
                    this.eventsService.announceLogout();
                    return Promise.reject(err.message || err);
                }
                else {
                    return Promise.reject(err.message || err);
                }
            });
    }

    getWithHeaders(route, authentication, fromApi) {
        let url = fromApi ? this.apiUrl + route : route;
        let options = authentication ? this.getAuthOptions() : this.options;
        return this.http.get(url, options)
            .toPromise()
            .then((res) => {
                this.checkResponseStatus(res);
                return res;
            })
            .catch((err) => {
                if (err.status === 401 && this.storageService.get('refresh_token') != null) {
                    return this.refreshAccessToken().then(() => {
                        return this.getWithHeaders(route, authentication, fromApi);
                    }).catch((err) => { return Promise.reject(err.message || err); });
                }
                else if (err.status === 401) {
                    this.eventsService.announceLogout();
                    return Promise.reject(err.message || err);
                }
                else {
                    return Promise.reject(err.message || err);
                }
            });
    }

    getFile(route, fromApi) {
        let url = fromApi ? this.apiUrl + route : route;
        let options =  this.getOptionsFile();
        return this.http.get(url, options)
            .toPromise()
            .then((response) => {
                return Promise.resolve(response);
            })
            .catch((err) => {
                if (err.status === 401 && this.storageService.get('refresh_token') != null) {
                    return this.refreshAccessToken().then(() => {
                        return this.getFile(route, fromApi);
                    }).catch((err) => { return Promise.reject(err.message || err); });
                }
                else if (err.status === 401) {
                    this.eventsService.announceLogout();
                    return Promise.reject(err.message || err);
                }
                else {
                    return Promise.reject(err.message || err);
                }
            });
    }

    post(route, message, authentication, fromApi) {
        let options = authentication ? this.getAuthOptions() : this.options;
        let data = message != null ? this.buildJSON(message) : "";
        let url = fromApi ? this.apiUrl + route : route;

        return this.http.post(url, data, options)
            .toPromise()
            .then(this.extractData)
            .catch((err) => {
                if (err.status === 401 && this.storageService.get('refresh_token') != null) {
                    return this.refreshAccessToken().then(() => {
                        return this.post(route, message, authentication, fromApi);
                    }).catch((err) => { return Promise.reject(err.message || err); });
                }
                else if (err.status === 401) {
                    this.eventsService.announceLogout();
                    return Promise.reject(err.message || err);
                }
                else {
                    return Promise.reject(err.message || err);
                }
            });
    }

    postMultipart(route, formData, authentication, fromApi) {
        return new Promise((resolve, reject) => {
            let url = fromApi ? this.apiUrl + route : route;
            let xhr: XMLHttpRequest = new XMLHttpRequest();

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200 || xhr.status === 201) {
                        resolve(JSON.parse(xhr.response));
                    } else if (xhr.status == 401 && this.storageService.get('refresh_token') != null) {
                        return this.refreshAccessToken().then(() => {
                            return this.postMultipart(route, formData, authentication, fromApi).then(() => resolve())
                                .catch(err => reject(err));
                        }).catch((err) => { reject(err.message || err); });
                    }
                    else if (xhr.status === 401) {
                        this.eventsService.announceLogout();
                        reject(JSON.parse(xhr.response));
                    }
                    else {
                        reject(JSON.parse(xhr.response));
                    }
                }
            };

            xhr.open('POST', url, true);
            if (authentication) {
                xhr.setRequestHeader("Authorization", 'Bearer ' + this.storageService.get('access_token'));
            }

            xhr.send(formData);
            return xhr;
        });
    }

    putMultipart(route, formData, authentication, fromApi) {
        return new Promise((resolve, reject) => {
            let url = fromApi ? this.apiUrl + route : route;
            let xhr: XMLHttpRequest = new XMLHttpRequest();

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        resolve(JSON.parse(xhr.response));
                    } else if (xhr.status == 401 && this.storageService.get('refresh_token') != null) {
                        return this.refreshAccessToken().then(() => {
                            return this.putMultipart(route, formData, authentication, fromApi).then(() => resolve())
                                .catch(err => reject(err));
                        }).catch((err) => { reject(err.message || err); });
                    }
                    else if (xhr.status === 401) {
                        this.eventsService.announceLogout();
                        reject(JSON.parse(xhr.response));
                    }
                    else {
                        reject(JSON.parse(xhr.response));
                    }
                }
            };

            xhr.open('PUT', url, true);
            if (authentication) {
                xhr.setRequestHeader("Authorization", 'Bearer ' + this.storageService.get('access_token'));
            }

            xhr.send(formData);
            return xhr;
        });
    }

    put(route, message, authentication, fromApi) {
        let options = authentication ? this.getAuthOptions() : this.options;
        let data = message != null ? this.buildJSON(message) : "";
        let url = fromApi ? this.apiUrl + route : route;

        return this.http.put(url, data, options)
            .toPromise()
            .then(() => { return Promise.resolve(); })
            .catch((err) => {
                if (err.status === 401 && this.storageService.get('refresh_token') != null) {
                    return this.refreshAccessToken().then(() => {
                        return this.put(route, message, authentication, fromApi);
                    }).catch((err) => { return Promise.reject(err.message || err); });
                }
                else if (err.status === 401) {
                    this.eventsService.announceLogout();
                    return Promise.reject(err.message || err);
                }
                else {
                    return Promise.reject(err.message || err);
                }
            });
    }


    upload(url: string, files) {
        return new Promise((resolve, reject) => {
            let xhr: XMLHttpRequest = new XMLHttpRequest();
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        resolve();
                    } else if (xhr.status == 401 && this.storageService.get('refresh_token') != null) {
                        return this.refreshAccessToken().then(() => {
                            return this.upload(url, files).then(() => resolve())
                                .catch(err => reject(err));
                        }).catch((err) => { reject(err.message || err); });
                    }
                    else if (xhr.status === 401) {
                        this.eventsService.announceLogout();
                        reject(JSON.parse(xhr.response));
                    }
                    else {
                        reject(JSON.parse(xhr.response));
                    }
                }
            };

            xhr.open('POST', url, true);
            xhr.setRequestHeader("Authorization", "Bearer " + this.storageService.get('access_token'));

            let formData = new FormData();

            for (let i = 0; i < files.length; i++) {
                formData.append("uploads[]", files[i].file, files[i].file.name);
                formData.append("filesInfo[]", files[i].id);
            }
            xhr.send(formData);
        });
    }

    updateTokens(accessToken, refreshToken, persistent) {
        this.storageService.set('access_token', accessToken, persistent);
        this.storageService.set('refresh_token', refreshToken, persistent);
        this.initHeaders();
    }

    clearTokens() {
        this.storageService.remove('access_token');
        this.storageService.remove('refresh_token');
        this.storageService.setPersistent(false);
        this.initHeaders();
    }
}
