import { Injectable } from '@angular/core';
import { CookieService, CookieOptionsArgs } from 'angular2-cookie/core';

@Injectable()

export class StorageService {
    constructor(private cookieService: CookieService) { }

    set(key: string, value: string, persistent: boolean): void {
        let opts: CookieOptionsArgs =  {
            secure: location.protocol == 'https' ? true : null,
            expires: persistent ? new Date(+new Date + 12096e5) : null
        };

        this.setPersistent(persistent);
        this.cookieService.put(key, value, opts);
    }

    get(key: string): string {
        return this.cookieService.get(key);
    }

    remove(key: string): void {
        this.cookieService.remove(key);
    }

    setPersistent(persistent: boolean): void {
        localStorage.setItem('persistent', persistent.toString());
    }

    isPersistent(): boolean {
        return localStorage.getItem('persistent') == 'true';
    }
} 