import { NgModule, enableProdMode, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { HashLocationStrategy, PathLocationStrategy, LocationStrategy } from '@angular/common';
import { Ng2PageScrollModule } from 'ng2-page-scroll';
import { ImageUploadModule } from 'angular2-image-upload';
//Routing
import { AppRoutingModule } from './app-routing.module';
//Components
import { AppComponent } from './app.component';

import { AgmCoreModule } from '@agm/core';
import { ReCaptchaModule } from 'angular2-recaptcha';
import { WebpackTranslateLoader } from '../webpack-translate-loader';
import { DragulaModule } from 'ng2-dragula';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import localeEs from '@angular/common/locales/es';
import localeCa from '@angular/common/locales/ca';
import { registerLocaleData } from '@angular/common';
import { ContextMenuModule } from 'ngx-contextmenu';
import { CookieService } from 'angular2-cookie';
import { StorageService } from './services/services/storage.service';
import { EventsService } from './services/services/events.service';
import { AppHttpService } from './services/services/app-http.service';
import { WebApiService } from './services/services/web-api.service';

enableProdMode();
registerLocaleData(localeCa);
registerLocaleData(localeEs);

@NgModule({
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: WebpackTranslateLoader
			}
		}),
		Ng2PageScrollModule.forRoot(),
		ImageUploadModule.forRoot(),
		AgmCoreModule.forRoot({
			apiKey: 'AIzaSyC8c5QmrrFl0ySiA6_pb2smNQhNPgPQZmY'
		}),
		ReCaptchaModule,
		DragulaModule.forRoot(),
		CalendarModule.forRoot({
			provide: DateAdapter,
			useFactory: adapterFactory
		}),
		ContextMenuModule.forRoot()
	],
	declarations: [
		//Components
		AppComponent
	],
	providers: [
		{ provide: LocationStrategy, useClass: PathLocationStrategy },
		{ provide: LOCALE_ID, useValue: 'ca' },
		{
			provide: LOCALE_ID,
			deps: [TranslateService],      //some service handling global settings
			useFactory: (translateService) => translateService.currentLang  //returns locale string
		},
		CookieService,
        StorageService,
        EventsService,
        AppHttpService,
        WebApiService
	],
	bootstrap: [AppComponent]
})

export class AppModule { }