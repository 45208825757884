import { Injectable } from '@angular/core';
import { AppHttpService } from './app-http.service';
import { Recover } from '../../models/Recover';
import { ScheduleService } from '../../models/schedule/ScheduleService';

@Injectable()

export class WebApiService {

    constructor(
        private http: AppHttpService
    ) { }

    postRegister(register, language) {
        var data = "Name=" + register.name + "&Surname=" + register.surname
            + "&DNI=" + register.dni + "&Phone=" + register.phone
            + "&Email=" + register.email + "&CaptchaKey=" + register.captchaKey + "&language="+ language;

        return this.http.postEncoded('/Users', data, false);
    }

    login(login) {
        var data = "username=" + login.username.trim() + "&client_id=" + login.username.trim() + "&password=" + login.password
            + "&grant_type=password";

        return this.http.postEncoded('/connect/token', data, false);
    }

    validateClientId(clientId) {
        return this.http.get("/validate/client_id?id=" + clientId, false, false);
    }   

    uploadGtfs(files) {
        return this.http.upload("/GTFS", files);
    }

    createGtfs(network: string) {
        return this.http.post(`/GTFSTasks/${network}`, null, true, false);
    }

    checkGtfsTask(taskId) {
        let url = "/GTFSTasks?id=" + taskId;
        return this.http.get(url, true, false);
    }

    getGtfsFiles(network: string) {
        return this.http.get(`/GTFS/${network}`, true, false);
    }

    getxtFile(route) {
        return this.http.getFile(route, false, false, 'application/octet-stream').then((response) => {
            return response.text();
        });
    }

    getUsers(page, filters) {
        let url = "/users";
        if (page || page == 0) {
            url += "?page=" + page;
        }
        if (filters) {
            url += ((url.indexOf("?") == -1 ? "?" : "&") + filters);
        }
        return this.http.getWithHeaders(url, true, false);
    }

    getUsersInfo() {
        return this.http.get("/usersinfo", true, false);
    }

    updateUser(user) {
        return this.http.put("/Users/" + user.id, user, true, false);
    }
    sendUserCredentialsEmail(userId) {
        return this.http.post("/UserCredentialsEmails", userId, true, false);
    }
    sendUserCredentialsEmailRecover(recover: Recover) {
        return this.http.post("/UserCredentialsEmailsLanding", recover, true, false);
    }

    getLines(page, sort, filters) {
        let url = "/lines";
        if (page || page == 0) {
            url += "?page=" + page;
        }
        if (sort) {
            url += ((url.indexOf("?") == -1 ? "?" : "&") + "sort=" + sort);
        }
        if (filters) {
            url += ((url.indexOf("?") == -1 ? "?" : "&") + filters);
        }
        return this.http.getWithHeaders(url, true, true);
    }

    getLine(id: number) {
        return this.http.get("/lines/" + id, true, true);
    }

    postLine(line) {
        let formData = new FormData();
        formData.append('Name', line.name);
        if (line.description.ca) { formData.append('Description_CA', line.description.ca); }
        if (line.description.es) { formData.append('Description_ES', line.description.es); }
        if (line.description.en) { formData.append('Description_EN', line.description.en); }        
        formData.append('NetworkId', line.network.id);
        formData.append('Code', line.code);
        formData.append('ImageFile', line.imageFile);

        return this.http.postMultipart('/lines', formData, true, true);
    }

    putLine(line) {
        let formData = new FormData();
        formData.append('Id', line.id);
        formData.append('Name', line.name);     
        if (line.description.ca) { formData.append('Description_CA', line.description.ca); }
        if (line.description.es) { formData.append('Description_ES', line.description.es); }
        if (line.description.en) { formData.append('Description_EN', line.description.en); }    
        formData.append('NetworkId', line.network.id);
        formData.append('Code', line.code);
        if (line.image) {
            formData.append('Image', line.image);
        } else {
            formData.append('ImageFile', line.imageFile);
        }

        return this.http.putMultipart('/lines', formData, true, true);
    }

    deleteLine(lineId) {
        return this.http.delete('/lines/' + lineId, true, true);
    }


    getNetworks() {
        return this.http.get("/networks", true, true);
    }

    getConnections(page, sort, filters) {
        let url = "/connections";
        if (page || page == 0) {
            url += "?page=" + page;
        }
        if (sort) {
            url += ((url.indexOf("?") == -1 ? "?" : "&") + "sort=" + sort);
        }
        if (filters) {
            url += ((url.indexOf("?") == -1 ? "?" : "&") + filters);
        }
        return this.http.getWithHeaders(url, true, true);
    }

    getConnection(id: number) {
        return this.http.get("/connections/" + id, true, true);
    }

    postConnection(connection) {
        let formData = new FormData();
        formData.append('Name', connection.name);
        formData.append('Latitude', connection.latitude);
        formData.append('Longitude', connection.longitude);
        formData.append('ImageFile', connection.imageFile);

        return this.http.postMultipart('/connections', formData, true, true);
    }

    putConnection(connection) {
        let formData = new FormData();
        formData.append('Id', connection.id);
        formData.append('Name', connection.name);
        formData.append('Latitude', connection.latitude);
        formData.append('Longitude', connection.longitude);
        if (connection.image) {
            formData.append('Image', connection.image);
        } else {
            formData.append('ImageFile', connection.imageFile);
        }

        return this.http.putMultipart('/connections', formData, true, true);
    }

    deleteConnection(connectionId) {
        return this.http.delete('/connections/' + connectionId, true, true);
    }

    getStops(page, sort, filters) {
        let url = "/stops";
        if (page || page == 0) {
            url += "?page=" + page;
        }
        if (sort) {
            url += ((url.indexOf("?") == -1 ? "?" : "&") + "sort=" + sort);
        }
        if (filters) {
            url += ((url.indexOf("?") == -1 ? "?" : "&") + filters);
        }
        return this.http.getWithHeaders(url, true, true);
    }

    getStop(id: number) {
        return this.http.get("/stops/" + id, true, true);
    }

    postStop(stop) {
        let formData = new FormData();
        formData.append('Name', stop.name);      
        if (stop.description.ca) { formData.append('Description_CA', stop.description.ca); }
        if (stop.description.es) { formData.append('Description_ES', stop.description.es); }
        if (stop.description.en) { formData.append('Description_EN', stop.description.en); }    
        formData.append('Latitude', stop.latitude);
        formData.append('Longitude', stop.longitude);
        formData.append('OutboundCode', stop.outboundCode);
        formData.append('ReturnCode', stop.returnCode);
        formData.append('GtfsCode', stop.gtfsCode);
        formData.append('ImageFile', stop.imageFile);

        return this.http.postMultipart('/stops', formData, true, true);
    }

    putStop(stop) {
        let formData = new FormData();
        formData.append('Id', stop.id);
        formData.append('Name', stop.name);
        if (stop.description.ca) { formData.append('Description_CA', stop.description.ca); }
        if (stop.description.es) { formData.append('Description_ES', stop.description.es); }
        if (stop.description.en) { formData.append('Description_EN', stop.description.en); }    
        formData.append('Latitude', stop.latitude);
        formData.append('Longitude', stop.longitude);
        formData.append('OutboundCode', stop.outboundCode);
        formData.append('ReturnCode', stop.returnCode);
        formData.append('GtfsCode', stop.gtfsCode);
        if (stop.image) {
            formData.append('Image', stop.image);
        } else {
            formData.append('ImageFile', stop.imageFile);
        }

        return this.http.putMultipart('/stops', formData, true, true);
    }

    deleteStop(stopId) {
        return this.http.delete('/stops/' + stopId, true, true);
    }

    getOffices(page, sort, filters) {
        let url = "/offices";
        if (page || page == 0) {
            url += "?page=" + page;
        }
        if (sort) {
            url += ((url.indexOf("?") == -1 ? "?" : "&") + "sort=" + sort);
        }
        if (filters) {
            url += ((url.indexOf("?") == -1 ? "?" : "&") + filters);
        }
        return this.http.getWithHeaders(url, true, true);
    }

    deleteOffice(id) {
        return this.http.delete('/offices/' + id, true, true);
    }

    getOffice(id) {
        return this.http.get("/offices/" + id, true, true);
    }

    postOffice(office) {
        let formData = new FormData();
        formData.append('Name', office.name);
        if (office.description.ca) { formData.append('Description_CA', office.description.ca); }
        if (office.description.es) { formData.append('Description_ES', office.description.es); }
        if (office.description.en) { formData.append('Description_EN', office.description.en); }    
        formData.append('Latitude', office.latitude);
        formData.append('Longitude', office.longitude);

        formData.append('ImageFile', office.imageFile);

        return this.http.postMultipart('/offices', formData, true, true);
    }

    putOffice(office) {
        let formData = new FormData();
        formData.append('Id', office.id);
        formData.append('Name', office.name);      
        if (office.description.ca) { formData.append('Description_CA', office.description.ca); }
        if (office.description.es) { formData.append('Description_ES', office.description.es); }
        if (office.description.en) { formData.append('Description_EN', office.description.en); }    
        formData.append('Latitude', office.latitude);
        formData.append('Longitude', office.longitude);
        if (office.image) {
            formData.append('Image', office.image);
        } else {
            formData.append('ImageFile', office.imageFile);
        }

        return this.http.putMultipart('/offices', formData, true, true);
    }

    getPointsOfSale(page, sort, filters) {
        let url = "/pointsofsale";
        if (page || page == 0) {
            url += "?page=" + page;
        }
        if (sort) {
            url += ((url.indexOf("?") == -1 ? "?" : "&") + "sort=" + sort);
        }
        if (filters) {
            url += ((url.indexOf("?") == -1 ? "?" : "&") + filters);
        }
        return this.http.getWithHeaders(url, true, true);
    }

    deletePointOfSale(id) {
        return this.http.delete('/pointsofsale/' + id, true, true);
    }

    getPointOfSale(id) {
        return this.http.get("/pointsofsale/" + id, true, true);
    }

    postPointOfSale(pos) {
        return this.http.post('/pointsofsale', pos, true, true);
    }

    putPointOfSale(id, pos) {
        return this.http.put('/pointsofsale/'+ id, pos, true, true);
    }

    getLineStops(lineId) {
        return this.http.get('/lines/' + lineId + '/stops?page=0', true, true);
    }

    putLineStop(lineId, stop) {
        return this.http.put('/lines/' + lineId + '/stops', stop, true, true);
    }

    deleteLineStop(lineId, stopId) {
        return this.http.delete('/lines/' + lineId + '/stops/' + stopId, true, true);
    }

    getStopConnections(stopId) {
        return this.http.get('/stops/' + stopId + '/connections?page=0', true, true);
    }

    putStopConnection(stopId, stopConnection) {
        return this.http.put('/stops/' + stopId + '/connections', stopConnection, true, true);
    }

    deleteStopConnection(stopId, connectionId) {
        return this.http.delete('/stops/' + stopId + '/connections/' + connectionId, true, true);
    }

    updateStaticData(url) {
        return this.http.post(url, null, true, false);
    }

    getLastUpdateStaticData() {
        return this.http.get("StaticDataUpdate", true, false);
    }

    getScheduleServices(network: string) {
        return this.http.get(`schedules/services?network=${network}`, true, false);
    }

    postScheduleService(service: ScheduleService, network: string) {
        // Set time to 12:00:00 to avoid timezone issues
        service.holidayDates.forEach(date => date.date.setHours(12,0,0,0))

        let formData = new FormData();
        formData.append('Network', network);
        formData.append('Name', service.name);
        formData.append('Days', JSON.stringify(service.days));
        formData.append('HolidayDates', JSON.stringify(service.holidayDates));
        formData.append('IsHoliday', service.isHoliday.toString());
        formData.append('Excel', service.excel);

        return this.http.postMultipart('/schedules/services', formData, true, false);
    }

    putScheduleService(service: ScheduleService) {
        // Set time to 12:00:00 to avoid timezone issues
        service.holidayDates.forEach(date => date.date.setHours(12,0,0,0))
        return this.http.put(`/schedules/services/${service.id}`, service, true, false);
    }

    deleteScheduleService(serviceId: number) {
        return this.http.delete(`/schedules/services/${serviceId}`, true, false);
    }
}