import { Component } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
    selector: 'main-app',
    template: '<router-outlet></router-outlet>'
})

export class AppComponent {
    constructor(
        private translateService: TranslateService
    ) {
        // this language will be used as a fallback when a translation isn't found in the current language
        translateService.setDefaultLang('ca');
        // the lang to use, if the lang isn't available, it will use the current loader to get them
        var supportedLanguages = ['ca', 'es', 'en'];

        // Try to get the user language from localStorage (previous sessions), if it doesn't exist, the default browser
        // langauge is selected.
        var userLanguage = localStorage.getItem('language')
        if (!userLanguage)
            userLanguage = (navigator.language.indexOf('-') != -1) ?
                navigator.language.substring(0, navigator.language.indexOf('-')) :
                navigator.language;

        if (supportedLanguages.indexOf(userLanguage) === -1) {
            userLanguage = 'en';
        }
        translateService.use(userLanguage);
        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => localStorage.setItem('language', event.lang));
    }
}